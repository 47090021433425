import MaxWidth from 'components/functional/maxWidth'
import Layout from 'components/global/layout'
import GallerySlider from 'components/index/gallerySlider'
import PageBody from 'components/shared/pageBody'
import PageHeader from 'components/shared/pageHeader'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import React from 'react'

import HawesCurtisLogo from 'svgs/hawescurtis.svg'

const Partners = ({ data }) => {
  const images = data.Images.nodes
  const meta = {
    title: 'Partners | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <MaxWidth $wooden>
        <PageBody $partners>
          <h1 className='pageTitle'>Partners</h1>
          <aside>
            <StaticImage src="../images/logos/GWR.png" alt="" layout="fullWidth" placeholder='NONE' />
          </aside>
          {/* <section className="partner-logo">
          </section> */}
          <p className="smaller-text">
          The Mousetrap, Agatha Christie's famous murder mystery play, has teamed up with GWR, a leading UK train company, to celebrate the play's legacy and Christie's love for train travel. The partnership coincides with The Mousetrap's 70th anniversary tour, taking murder on the move to over 70 venues. 
          </p>
          <p className="smaller-text">
          GWR, known for its rich history and scenic routes, aligns perfectly with the nostalgic charm of The Mousetrap. Cast members were photographed at Paddington station, capturing the glamour and nostalgia of train travel. GWR's network connects London with destinations like Agatha Christie's Devon and her Georgian country home, Greenway, offering fans a chance to further indulge in her world of mystery. 
          </p>
          <p>Find out more on the&nbsp;<a href="https://www.gwr.com/stations-and-destinations/travel-inspiration/festivals-and-events/agatha-christies-the-mousetrap" target="_blank" rel="noopener noreferrer">GWR website</a>.</p>
          <GallerySlider data={images} $noReadMore />
          <div className='mt-12'>
            <a href="https://58andco.com/" target="_blank" rel="noopener noreferrer">
          <StaticImage src="../images/partner/58-and-CO.jpg" width={300} alt="" placeholder='NONE' />
            </a>
          <p className="smaller-text mt-12">
          58 and Co. is an award-winning distillery (awarded ‘UK Gin Producer of The Year’ in 2020 by IWSC) and has grown from an ultra-small, batch-production gin distillery into a large-scale producer known for creating exceptional spirits. It has teamed up with The Mousetrap in celebration of its 70th year in the West End, creating a bespoke gin which is on sale only at the St Martins Theatre.  
          </p>
          <p className="smaller-text mt-3">
          Experience The Mousetrap 70th Anniversary Edition Gin, where zesty citrus, delicate florals, and aromatic herbs converge. Our gin is a harmonious fusion of fresh grapefruit, sweet orange, zesty coriander and a beautiful juniper backbone creating a delightful and tantalizing flavour profile.
          </p>
          <p className="smaller-text mt-3">
          Our gin is more than just a beverage; it's an exceptional experience, enjoyed with Fever Tree Tonic for the perfect Theatre tipple. Cheers to the vibrant spirit of 58 and Co. and The Mousetrap!
          </p>
          <StaticImage src="../images/partner/gin.jpg" width={600} alt="" placeholder='NONE'  class='mt-12'/>
          </div>
        </PageBody>
      </MaxWidth>
    </Layout>
  )
}

export default Partners

export const PartnersQuery = graphql`
  query {
    Images: allPartnerJson {
      nodes {
        image {
          alt
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: FULL_WIDTH
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
